<script setup lang="ts">
const props = withDefaults(defineProps<{
  backgroundImage?: string
  btnImage?: string
}>(), {
  backgroundImage: '/img/page-accueil-haut-de-page.png',
  btnImage: '/img/picto-cadeau-violet-fonce.svg',
})

defineSlots<{
  title: () => any
  subtitle: () => any
  btnText: () => any
}>()

const $img = useImage()
const backgroundImage = computed(() => `url(${$img(props.backgroundImage, {
  quality: 80,
  format: 'webp',
  height: 1200,
})})`)
</script>

<template>
  <div class="w-full lg:px-14 md:px-8 xl:px-20">
    <div
      class="h-4xl w-full flex flex-col justify-end bg-[calc(80%)_0px] bg-cover md:bg-top-right"
      :style="{ backgroundImage }"
    >
      <div class="absolute h-xl w-full bg-void bg-opacity-80 pt-20 lg:w-90% lg:rounded-tr-full xl:w-75%" />

      <div class="z-10 self-center compact-container">
        <div class="h-xl flex flex-col self-center pt-20 lt-lg:items-center">
          <div>
            <h1 class="text-3xl text-[#FABD05] font-laviossa lt-2xl:max-w-[600px] lt-lg:text-center">
              <MDCSlot name="title" unwrap="p" />
              <br>
              <span class="text-2xl text-white">
                <MDCSlot name="subtitle" unwrap="p" />
              </span>
            </h1>
          </div>

          <div class="mt-10">
            <VBtn to="/plateforme-accompagnement">
              <span class="flex flex-row items-center justify-center tracking-wider md:gap-2">
                <NuxtImg :src="btnImage" alt="Button Icon" class="h-5 w-5" :height="40" :width="40" />
                <span class="px-2 py-2 text-3 font-ubuntu uppercase md:text-5">
                  <MDCSlot name="btnText" unwrap="p" />
                </span>
              </span>
            </VBtn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add any additional styles here */
</style>
